import axios from 'axios';
import Swal from 'sweetalert2';
import * as actionTypes from "../action-types";
import { api_urls } from '../../utils/api-urls';
import { admin_login, get_admin_detail } from '../../utils/api-routes';
import { put, call, takeLeading } from 'redux-saga/effects';
import { access_token, refresh_token } from '../../utils/constant';
import { postAPI } from '../../utils/api-function';

function* adminLogin(action) {
    try {
        const { payload } = action;
        console.log("Payload ::: ", payload)

        const { data } = yield axios.post(api_urls + admin_login, payload?.data);
        console.log("Admin Login Saga Response ::: ", data);

        if (data?.token) {
            Swal.fire({ icon: "success", text: "Login Successfully", showConfirmButton: false, timer: 2000 });
            localStorage.setItem(access_token, data?.token);
            localStorage.setItem(refresh_token, data?.token);
            localStorage.setItem('adminId', data?.results?._id);
            yield call(payload?.onComplete);
            // localStorage.setItem('user_type', data?.results?.type);
            // localStorage.setItem('permissions', JSON.stringify(data?.results?.Permissions));
            yield put({ type: actionTypes.GET_ADMIN_DETAIL, payload: { data: { id: data?.results?._id } } });
        }

    } catch (error) {
        Swal.fire({ icon: "error", text: error?.response?.data ? error?.response?.data?.message : 'Failed To Login', showConfirmButton: true, timer: 2000, });
        console.log("Admin Login Saga Error ::: ", error)
    }
};

function* getAdminDetail(action) {
    try {
        const { payload } = action;
        console.log("Payload ::: ", payload)

        const { data } = yield postAPI(get_admin_detail, payload?.data);
        // console.log("Admin Detail Saga Response ::: ", data);

        if (data?.success) {
            yield put({ type: actionTypes.SET_ADMIN_DETAIL, payload: data?.results });
            if (data?.results?.type === "admin") {
                yield put({ type: actionTypes.SET_ADMIN_PERMISSION, payload: ['Dashboard', 'Player', 'Game History', 'Current Game', 'Payment', 'Setting'] });
            } else {
                yield put({ type: actionTypes.SET_ADMIN_PERMISSION, payload: data?.results?.Permissions });
            }
        } else {
            yield put({ type: actionTypes.SET_ADMIN_DETAIL, payload: {} });
            localStorage.clear();
            payload?.navigate('/login');
        }

    } catch (error) {
        console.log("Admin Detail Saga Error ::: ", error);
    }
};

export default function* authSaga() {
    yield takeLeading(actionTypes.ADMIN_LOGIN, adminLogin);
    yield takeLeading(actionTypes.GET_ADMIN_DETAIL, getAdminDetail);
}