import { combineReducers } from "redux";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import playerReducer from "./playerReducer";
import gameHistoryReducer from "./gameHistoryReducer";
import currentGameReducer from "./currentGameReducer";
import paymentReducer from "./paymentReducer";
import settingReducer from "./settingReducer";

const rootReducer = combineReducers({
    commonReducer,
    authReducer,
    playerReducer,
    gameHistoryReducer,
    currentGameReducer,
    paymentReducer,
    settingReducer
});

export default rootReducer;