import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import playerSaga from "./playerSaga";
import gameHistorySaga from './gameHistorySaga';
import currentGameSaga from './currentGameSaga';
import paymentSaga from './paymentSaga';
import settingSaga from './settingSaga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        playerSaga(),
        gameHistorySaga(),
        currentGameSaga(),
        paymentSaga(),
        settingSaga()
    ])
};